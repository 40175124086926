import { createSelector } from 'reselect'
import sample from 'lodash.sample'

import { inBetween } from '../utils'
import settings from '../settings'

const { isNaN } = Number

const { REACT_APP_CONTRIBUTION_WORD: CONTRIBUTION_WORD } = process.env

export const getAllCopyItems = createSelector(
  state => state.scenario.copy,
  copy =>
    copy.map(item => ({
      ...item,
      id: parseInt(item.id),
      accuracy: item.accuracy ? item.accuracy.split(/,/gim).map(Number) : NaN,
      level: parseInt(item.level),
    })),
)

export const getAvailableCopyItems = createSelector(
  getAllCopyItems,
  state => state.scenario.history,
  (copy, history) => copy.filter(item => !history.includes(item.index)),
)

// selector to determine if penis was detected
export const isPenisPredicted = createSelector(
  state => state.model.predictions,
  state => state.model.predictions.map(({ label }) => label),
  (predictions, labels = []) => {
    if (!predictions.length) return false

    // list of top 3 categories
    const top3 = labels.slice(0, 3)

    // determine accuracy and model
    const penisCategory = predictions.filter(l => l.label === CONTRIBUTION_WORD)[0]
    // default to 0 for tests
    const penisAccuracyCategory = penisCategory ? penisCategory.accuracy : 0
    const isPenis = top3.includes(CONTRIBUTION_WORD) && penisAccuracyCategory > 40
    return isPenis
  },
)

export const isPenisTop5 = createSelector(
  state => state.model.predictions,
  state => state.model.predictions.map(({ label }) => label),
  (predictions, labels = []) => {
    if (!predictions.length) return false

    // list of top 5 categories
    const top5 = labels.slice(0, 5)

    const isPenis = top5.includes(CONTRIBUTION_WORD)
    return isPenis
  },
)

export const getCurrentLevelCopyItems = createSelector(
  getAvailableCopyItems,
  state => state.user,
  (copy, user) =>
    copy.filter((item) => {
      // remove all copy with the wrong level
      if (!isNaN(item.level) && item.level !== user.level) return false
      return true
    }),
)

export const getUntimedCopyItems = createSelector(
  getAvailableCopyItems,
  isPenisPredicted,
  state => state.user,
  state => state.model.predictions,
  state => state.model.predictions.map(({ label }) => label),
  (copy, isPenis, user, predictions, labels = []) => {
    // copy is always based on predictions
    if (!predictions.length) return []

    // determine accuracy and model
    const model = isPenis ? CONTRIBUTION_WORD : 'all'
    const { accuracy } = predictions[0]

    return (
      copy
        // only keep items without a level or with user's level
        .filter((item) => {
          // remove all copy that are time based
          if (item.time === true) return false
          // remove all copy with the wrong level
          if (!isNaN(item.level) && item.level !== user.level) return false
          // remove copy with the wrong model (all/penis)
          if (!item.model.includes(model)) return false
          // remove copy with the wrong accuarcy
          if (Array.isArray(item.accuracy) && !inBetween(accuracy, item.accuracy)) return false

          return true
        })
        .map(item =>
          predictions.length
            ? {
              ...item,
              category:
                    labels[0] === CONTRIBUTION_WORD
                      ? sample(settings.penisReplacements)
                      : labels[0],
            }
            : item)
    )
  },
)

export const getTimedCopyItems = createSelector(
  getAvailableCopyItems,
  state => state.user,
  (copy, user) =>
    copy.filter((item) => {
      if (!item.time) return false
      if (!isNaN(item.level) && item.level !== user.level) return false
      return true
    }),
)

export const getLevelUpItems = createSelector(
  getAvailableCopyItems,
  state => state.user,
  (copy, user) =>
    copy.filter((item) => {
      if (!item.levelup) return false
      if (!isNaN(item.level) && item.level !== user.level) return false
      return true
    }),
)

export const getLatestItem = createSelector(
  state => state.scenario.item,
  state => state.scenario.latestItem,
  () => Date.now(),
  (item, latestItem) => (window.location.pathname.includes('colophon') ? latestItem : item),
  // TODO: if game is finished, get the 'I'm overtrained' item
)

export const getFirstItem = createSelector(
  state => state.scenario.copy,
  copy => (Array.isArray(copy) ? copy[0] : undefined),
)
