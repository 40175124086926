import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import classNames from 'classnames'

import '../scss/App.scss'
import { initApp } from '../actions/app'
import DocumentProxy from '../proxies/DocumentProxy'
import { cssVars } from '../utils'
import settings from '../settings'

import Home from './Home'
import Colophon from './Colophon'
import ErrorScreen from './ErrorScreen'

class App extends Component {
  componentWillMount() {
    this.props.initApp()
    DocumentProxy.init()
  }

  render() {
    const appClass = classNames('App', {})

    return (
      <Route
        render={({ location }) => (
          <div className={appClass} style={cssVars(settings.css)}>
            <Switch location={location}>
              <Route exact path="/" component={Home} />
              <Route exact path="/colophon" component={Colophon} />
              <Route exact path="/error" component={ErrorScreen} />
              <Route render={() => (window.location.href = '/')} />
            </Switch>
          </div>
        )}
      />
    )
  }
}

const mapDispatchToProps = {
  initApp,
}

export default connect(
  null,
  mapDispatchToProps,
)(App)
