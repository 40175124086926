/* eslint-disable no-unused-vars, no-use-before-define, no-alert */
import constants from './constants'
import { createPrediction, resetModel } from './model'
import CanvasProxy from '../proxies/CanvasProxy'
import { startTimer } from './scenario'

export const canvasChanged = timestamp => (dispatch) => {
  dispatch({ type: constants.CANVAS_CHANGED, data: timestamp })
}

export const scanCanvas = (data = {}) => async (dispatch) => {
  dispatch({ type: constants.SCAN_CANVAS, data })
  await dispatch(createPrediction(data))
}

export const startEraseCanvas = () => async (dispatch, getState) => {
  dispatch({ type: constants.START_ERASE_CANVAS })
  await CanvasProxy.eraseCanvas()
  dispatch(stopEraseCanvas())
  dispatch(startTimer())
}

export const stopEraseCanvas = () => async (dispatch, getState) => {
  dispatch({ type: constants.STOP_ERASE_CANVAS })
}

export const resetCanvas = data => (dispatch, getState) => {
  dispatch({ type: constants.RESET_CANVAS, data })
  dispatch(resetModel())
}
