import constants from '../actions/constants'

const initialState = {
  image: undefined,
  prevImage: undefined,
  drawing: [],
  bounds: {},
  contributions: 0,
  error: undefined,
  submitted: undefined,
  erasing: false,
  lastAddition: undefined,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.CANVAS_CHANGED:
      return {
        ...state,
        lastAddition: action.data,
      }

    case constants.SCAN_CANVAS:
      return {
        ...state,
        ...action.data,
        prevImage: state.image,
      }

    case constants.START_ERASE_CANVAS:
      return {
        ...state,
        erasing: true,
      }

    case constants.STOP_ERASE_CANVAS:
      return {
        ...state,
        erasing: false,
      }

    case constants.RESET_CANVAS:
      return {
        ...state,
        ...action.data,
        image: undefined,
        drawing: [],
        bounds: {},
        submitted: false,
      }

    default:
      return state
  }
}
