/* eslint-disable object-curly-newline, no-await-in-loop */

// Helper function that gives an array of 0,1...n
const range = n => [...Array(n).keys()]
function getSum(total, num) {
  return total + num
}

const rangeOfDrawing = (strokes) => {
  let xmax = -Infinity
  let xmin = Infinity
  let ymax = -Infinity
  let ymin = Infinity

  strokes.forEach(([xs, ys]) => {
    xmax = Math.max(...xs, xmax)
    xmin = Math.min(...xs, xmin)
    ymax = Math.max(...ys, ymax)
    ymin = Math.min(...ys, ymin)
  })

  const xptp = xmax - xmin
  const yptp = ymax - ymin
  const aspect = xptp / yptp

  return { xmin, xmax, xptp, ymin, ymax, yptp, aspect }
}

// Converts value from between min and max to between 0 and 1
const normValue = (value, min, max) => (value - min) / (max - min)

// Scales a list of values from between min and max to betwene destMin, destMax
const scaleValues = (values, min, max, destMin, destMax) =>
  values
    .map(value => normValue(value, min, max))
    .map(value => value * (destMax - destMin) + destMin)

// Scales strokes from an image with respect to the given margin and destSize.
const scaleStrokes = (strokes, margin, destSize) => {
  let { xmin, xptp, ymin, yptp } = rangeOfDrawing(strokes)

  const destptp = destSize - 2 * margin
  const destMin = 0 + margin

  let yScale = 1
  let xScale = 1
  xScale = destptp / yptp
  yScale = destptp / xptp

  const scale = Math.min(xScale, yScale)

  const ret = strokes.map(([xs, ys]) => {
    const xsScaled = xs.map(x => (x - xmin) * scale + destMin)
    const ysScaled = ys.map(y => (y - ymin) * scale + destMin)
    return [xsScaled, ysScaled]
  });
  ({ xmin, xptp, ymin, yptp } = rangeOfDrawing(ret)) // Center
  const centerX = x => x + (destptp - xptp) / 2.0
  const centerY = y => y + (destptp - yptp) / 2.0

  const centerStroke = stroke => [stroke[0].map(x => centerX(x)), stroke[1].map(y => centerY(y))]

  return ret.map(stroke => centerStroke(stroke))
}

// Removes the time dimension from a stroke
const removeTime = stroke => stroke.slice(0, 2)

// Removes strokes that are only a single point
const removeSingleStrokes = stroke => (stroke[0].length <= 1 ? undefined : stroke)

// Returns an array of colors (0-255) of the same shape as strokes
const getColors = (strokes) => {
  const length = strokes.map(([xs]) => xs.length).reduce(getSum)
  const colorTable = scaleValues(range(length), 0, length - 1, 0, 255 / 2)
  let pointCounter = 0
  const colors = []

  strokes.forEach((stroke) => {
    const xs = stroke[0]
    const colorSub = []
    xs.forEach(() => {
      colorSub.push(colorTable[pointCounter])
      pointCounter += 1
    })
    colors.push(colorSub)
  })
  return colors
}

class PredictImageProxy {
  static instance

  constructor() {
    if (PredictImageProxy.instance) return PredictImageProxy.instance
    PredictImageProxy.instance = this

    this.STROKE_WEIGHT = 5
    this.MARGIN = 5
    this.IMAGE_SIZE = 224
    this.strokes = []
  }

  convertToFlat = (stroke) => {
    // Example stroke passed:
    // 0: {x: 0.6153846153846154, y: 0.5231707317073171, t: 1555929905029, tool: "Pencil"}
    // 1: {x: 0.6144578313253012, y: 0.5231707317073171, t: 1555929905038, tool: "Pencil"}
    // 2: {x: 0.6144578313253012, y: 0.5231707317073171, t: 1555929905135, tool: "Pencil"}
    // 3: {x: 0.6135310472659871, y: 0.5231707317073171, t: 1555929905138, tool: "Pencil"}

    // Data format of the ndjson files
    // strokeIndex: [xs[length], ys[length], ts[length]]
    const xs = []
    const ys = []
    const ts = []
    stroke.forEach((point) => {
      xs.push(point.x)
      ys.push(point.y)
      ts.push(point.t)
    })
    return [xs, ys, ts]
  }

  prepareStrokes = (strokesIn) => {
    let strokes = strokesIn.map(this.convertToFlat)

    strokes = strokes.map(removeTime)
    strokes = scaleStrokes(strokes, this.MARGIN, this.IMAGE_SIZE)

    strokes = strokes
      .map(stroke => removeSingleStrokes(stroke))
      .filter(stroke => stroke !== undefined)
    if (strokes.length === 0) return strokes
    const colors = getColors(strokes)

    strokes = strokes.map(([xs, ys], strokeIndex) => [xs, ys, colors[strokeIndex]])
    return strokes
  }

  drawImage = (strokesIn) => {
    let strokes = strokesIn
      .map(stroke => (stroke.length < 1 ? undefined : stroke))
      .filter(stroke => stroke !== undefined)
    if (strokes.length === 0) return
    strokes = this.prepareStrokes(strokesIn)
    if (strokes.length === 0) return
    // Create canvas
    const canvas = document.createElement('canvas')
    canvas.height = this.IMAGE_SIZE
    canvas.width = this.IMAGE_SIZE
    const context = canvas.getContext('2d')
    context.lineWidth = this.STROKE_WEIGHT
    context.lineJoin = 'round'
    context.lineCap = 'round'
    context.fillStyle = 'white'
    context.fillRect(0, 0, canvas.width, canvas.height)
    strokes = strokes.reverse()
    strokes.forEach(([xs, ys, colors]) => {
      // Reverse the order
      const xLength = xs.length
      const points = xs.map((_x, xIndex) => [
        xs[xLength - xIndex],
        ys[xLength - xIndex],
        colors[xLength - xIndex],
      ])
      points.forEach(([x, y, color], pointIndex) => {
        if (pointIndex === 0) return
        const cValue = `rgb(${Math.round(color)}, ${Math.round(color)}, ${Math.round(color)})`

        context.strokeStyle = cValue
        context.beginPath()

        context.moveTo(points[pointIndex - 1][0], points[pointIndex - 1][1])
        context.lineTo(x, y)

        context.stroke()
        context.closePath()
      })
    })
    return context.getImageData(0, 0, this.IMAGE_SIZE, this.IMAGE_SIZE)
  }
}

export default new PredictImageProxy()
