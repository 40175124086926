/* eslint-disable no-underscore-dangle */

import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import { routerMiddleware, routerReducer } from 'react-router-redux'
import createHistory from 'history/createBrowserHistory'
import thunk from 'redux-thunk'

import rootReducer from './reducers'
import { storage } from './utils'

// reset app when user has finished the game in a previous setting
let saved
// temporarily disable save state
// try {
//   saved = storage.get('state')
// } catch (error) {
//   saved = undefined
// }

if (saved && saved.user.hasFinished) {
  const { uuid } = saved.user
  const state = { user: { uuid } }
  storage.set('state', state)
}

const browserHistory = createHistory()
const middleware = [thunk, routerMiddleware(browserHistory)]
const enhancers = []

if (process.env.NODE_ENV === 'development') {
  if (typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
  }
}

export const history = browserHistory
export const store = createStore(
  combineReducers({ router: routerReducer, ...rootReducer }),
  compose(applyMiddleware(...middleware), ...enhancers),
)
