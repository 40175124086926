/* eslint-disable object-curly-newline */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import device from 'current-device'
import Tappable from 'react-tappable'
import '../scss/Home.scss'
import { resumeApp, toggleMute } from '../actions/app'
import IntroScreen from './IntroScreen'
import CanvasHolder from './CanvasHolder'
import Pencil from './Pencil'
import Eraser from './Eraser'
import WarningIcon from './WarningIcon'
import Subtitles from './Subtitles'
import ShopLink from './ShopLink'
import Nav from './Nav'
import Debug from './Debug'

class Home extends Component {
  componentWillMount() {
    if (this.props.paused) this.props.resumeApp()
  }

  render() {
    const homeClass = classNames('Home', {})
    const { showIntroScreen, muted, showShopLink, showWarning } = this.props
    return (
      <div className={homeClass}>
        {showIntroScreen && <IntroScreen />}
        <CanvasHolder />
        {!showShopLink && <Subtitles />}
        <Eraser />
        {device.desktop() && <Pencil />}
        <Tappable
          onTap={this.props.toggleMute}
          className={`button ${muted ? 'mute-off' : 'mute-on'}`}
        />
        {showShopLink && <ShopLink />}
        {showWarning && <WarningIcon />}
        <Debug />
        <Nav />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  paused: state.app.paused,
  muted: state.app.muted,
  showDebug: state.app.showDebug,
  level: state.user.level,
  showIntroScreen: state.app.showIntroScreen,
  showShopLink: state.app.showShopLink,
  showWarning: state.app.showWarning,
})

const mapDispatchToProps = {
  resumeApp,
  toggleMute,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home)
