/* eslint-disable max-len, object-curly-newline, no-underscore-dangle */
import Raven from 'raven-js'
import { Howl, Howler } from 'howler'

import { noop, getAudioFilenames } from '../utils'

const SILENCE_URL = `${window.location.origin}/assets/audio/silence.mp3`
const ASSETS_URL = `${window.location.origin}/assets/audio`

class SpeechProxy {
  static instance
  initialized = false
  isPlaying = false
  muted = false
  audio

  constructor() {
    if (SpeechProxy.instance) return SpeechProxy.instance
    SpeechProxy.instance = this
  }

  init = () => {
    // play silence to "ask for permission" as we can't play audio without user interaction
    if (this.initialized === false) {
      this.initialized = true
      this.playUrl(SILENCE_URL)
    }
  }

  preloadAudioFile = url =>
    new Promise(async (resolve, reject) => {
      const errorHandler = (id, err) => {
        Raven.captureException(err)
        reject(err)
      }

      const errorLoadHandler = async (id, err) => {
        console.error('Howler load error', url)
        Raven.captureException(err)

        // fall back to silence in case we can not load audio file
        // at least the subtiles will show up then
        try {
          const sound = await this.preloadAudioFile(SILENCE_URL)
          resolve(sound)
        } catch (error) {
          reject(error)
        }
      }

      const sound = new Howl({
        src: [url],
        preload: true,
        mute: this.muted,
        onload: () => {
          resolve(sound)
        },
        onloaderror: errorLoadHandler,
        onplayerror: errorHandler,
      })
    })

  playHowl = async (url, { delay = 0, onEnded = noop }) => {
    // silence url should be already loaded when soundfile was not found
    const h = this.howls[url] || this.howls[SILENCE_URL]
    if (!h) {
      onEnded()
    } else {
      h.once('end', onEnded)
      this.timeout = setTimeout(() => {
        h.play()
      }, delay)
    }
  }

  playUrl = async (url, { delay = 0, onLoad = noop, onEnded = noop } = {}) => {
    this.playUrls([url], { delay, onLoad, onEnded })
  }

  playUrls = async (urls, { delay = 0, onLoad = noop, onEnded = noop } = {}) => {
    this.isLoading = true
    this.howls = {}
    const promises = urls.map(this.preloadAudioFile).map(p => p.catch(e => e))
    const results = await Promise.all(promises)
    const cleaned = results.filter(r => !(r instanceof Error))
    cleaned.forEach(c => (this.howls[c._src] = c))
    this.isLoading = false
    if (onLoad) onLoad(cleaned.length === urls.length)

    this.isPlaying = true
    const rest = 0
    let url = urls.shift()
    const loop = (u, t) => {
      this.playHowl(u, {
        delay: t,
        onEnded: () => {
          url = urls.shift()
          if (url) {
            loop(url, rest)
          } else {
            this.isPlaying = false
            if (typeof onEnded === 'function') {
              onEnded()
            }
          }
        },
      })
    }

    loop(url, delay)
  }

  say = async ({ text, subtitles, hasCategory, category }, onStart, onEnd) => {
    // don't load/play anything if we already play something or we're already loading something
    if (this.isPlaying || this.isLoading) return
    if (!text || !subtitles) {
      console.warn('text or subtitles missing')
      return
    }
    this.reset()

    const urls = getAudioFilenames({ text, hasCategory, category }).map(f => `${ASSETS_URL}/${f}`)

    if (onStart && typeof onStart === 'function') onStart()
    if (urls) {
      this.playUrls(urls, {
        onEnded: () => {
          this.handleAudioEnded(onEnd)
        },
      })
    } else {
      this.handleAudioEnded(onEnd)
    }
  }

  handleAudioEnded = (callback) => {
    this.isPlaying = false
    this.isLoading = false
    if (callback && typeof callback === 'function') callback()
  }

  toggleMute = () => {
    this.muted = !this.muted
    Howler.mute(!Howler._muted)
    if (!this.muted) {
      Howler._howls.forEach(h => h.mute(false))
    }
  }

  reset = () => {
    this.isPlaying = false
    if (this.timeout) clearTimeout(this.timeout)
    Howler._howls.forEach(h => h.pause())
  }

  destroy = () => {
    this.reset()
  }
}

export default new SpeechProxy()
