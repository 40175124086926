/* eslint-disable prefer-template */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import '../scss/Debug.scss'

class Debug extends Component {
  predictions = []

  render() {
    const debugClass = classNames('Debug', {})
    const { showDebug, predictions } = this.props
    this.predictions = predictions.length ? predictions : this.predictions

    return showDebug ? (
      <div className={debugClass}>
        <pre>
          {this.predictions.slice(0, 5).map(({ label, accuracy }) => (
            <span key={accuracy}>{`${label}: ${accuracy.toFixed(1)}%`}</span>
          ))}
        </pre>
      </div>
    ) : (
      []
    )
  }
}

const mapStateToProps = state => ({
  showDebug: state.app.showDebug,
  predictions: state.model.predictions,
})

export default connect(mapStateToProps)(Debug)
