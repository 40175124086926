/* eslint-disable no-unused-vars, no-use-before-define */
import ReactGA from 'react-ga'
import constants from './constants'
import { storage } from '../utils'

export const levelUp = data => (dispatch, getState) => {
  dispatch({ type: constants.LEVEL_UP, data })
  ReactGA.event({
    category: 'User',
    action: 'User level up',
    value: data,
    nonInteraction: true,
  })
}

export const warnUser = data => (dispatch, getState) => {
  dispatch({ type: constants.WARN_USER, data })
  ReactGA.event({
    category: 'User',
    action: 'Warn user',
    value: data,
    nonInteraction: true,
  })
}

export const saveUser = () => (dispatch, getState) => {
  dispatch({ type: constants.SAVE_USER })
  const { app,
    user,
    scenario: { history, latestItem } } = getState()
  const state = { app, user, scenario: { history, latestItem } }
  storage.set('state', state)
}

export const resetUser = data => (dispatch, getState) => {
  dispatch({ type: constants.RESET_USER })
  ReactGA.event({ category: 'User', action: 'Reset user', nonInteraction: true })
}
