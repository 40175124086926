import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import device from 'current-device'
import '../scss/IntroScreen.scss'
import { hideIntroScreen } from '../actions/app'

class IntroScreen extends Component {
  render() {
    const introScreenClass = classNames('IntroScreen', {})
    const { ready } = this.props

    return (
      <div
        className={introScreenClass}
        onTouchStart={ready ? this.props.hideIntroScreen : undefined}
        onMouseDown={ready ? this.props.hideIntroScreen : undefined}
      >
        <span>{ready ? (device.desktop() ? 'Start drawing' : 'Start drawing') : 'Loading'}</span>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ready: state.app.ready,
})

const mapDispatchToProps = {
  hideIntroScreen,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntroScreen)
