/* eslint-disable object-curly-newline */
import 'array-flat-polyfill'
import device from 'current-device'
import { debounce } from 'throttle-debounce'
import settings from '../settings'
import DocumentProxy from './DocumentProxy'
import ToolProxy from './ToolProxy'
import { store } from '../store'
import { scanCanvas, resetCanvas, canvasChanged } from '../actions/canvas'
import { dist, remap, getMousePosition, bounds } from '../utils'
import { stopTimer, startTimer } from '../actions/scenario'
import PredictImageProxy from './PredictImageProxy'
import { hideIntroScreen } from '../actions/app'

class CanvasProxy {
  static instance
  isMouseTarget = device.desktop()
  eraserStartPosition = {
    x: window.innerWidth - 75,
    y: window.innerHeight - 100,
  }

  constructor() {
    if (CanvasProxy.instance) return CanvasProxy.instance
    CanvasProxy.instance = this
    this.scanCanvas = debounce(settings.debouncePredictionDelay, this.scanCanvas)
  }

  init = (ref) => {
    if (this.canvas) this.destroy()
    this.canvas = ref.current
    this.context = this.canvas.getContext('2d')
    this.setInitialState()
    this.resizeCanvas()
    this.setStyles()
    this.setupListeners()
    this.updateStrokes()
    console.log('(CanvasProxy: init)')
  }

  setInitialState = () => {
    const { innerWidth: width, innerHeight: height } = window
    const landscape = width > height
    this.isDrawing = false
    this.isErasing = false
    this.strokeWeight = Math.min((landscape ? width : height) / 40, 13)
    if (!device.desktop()) this.strokeWeight = 9 // same-ish width as eraser shadow
    this.offset = this.strokeWeight * 2
    this.lineDist = 0
    this.maxLineDist = 4

    // NOTE: this preserves the points and bounds of current drawing
    if (this.points && this.points.length) return
    this.points = []
    this.addedLength = 0
    this.drawing = []
    this.bounds = { xMin: width, xMax: 0, yMin: height, yMax: 0 }
  }

  setStyles = () => {
    if (!this.context) return
    this.context.fillStyle = 'white'
    this.context.lineWidth = this.strokeWeight
    this.context.lineJoin = 'round'
    this.context.lineCap = 'round'
  }

  resizeCanvas = () => {
    if (!this.canvas) return
    const { innerWidth: width, innerHeight: height } = window
    this.canvas.style.width = width
    this.canvas.style.height = height
    this.canvas.width = width
    this.canvas.height = height
    this.eraserStartPosition.x = width - 75
    this.eraserStartPosition.y = height - 100
    this.setStyles()
    this.updateStrokes()
  }

  setupListeners = () => {
    DocumentProxy.addListener(window, 'resize', this.resizeCanvas)
    DocumentProxy.addListener(this.canvas, ['mousedown', 'touchstart'], this.handleMouseDown)
    DocumentProxy.addListener(this.canvas, ['mousemove', 'touchmove'], this.handleMouseMove)
    DocumentProxy.addListener(this.canvas, ['mouseup', 'touchend'], this.handleMouseUp)
    DocumentProxy.addListener(this.canvas, ['mouseleave'], this.handleMouseLeave)
  }

  handleMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!this.canvas || this.isErasing) return
    this.isDrawing = true

    store.dispatch(stopTimer())
    store.dispatch(hideIntroScreen())

    const mousePosition = getMousePosition(event)
    const point = {
      x: remap(mousePosition.x, 0, this.canvas.width, 0, 1),
      y: remap(mousePosition.y, 0, this.canvas.height, 0, 1),
      t: Date.now(),
      tool: 'Pencil',
    }
    this.lastAddition = Date.now()
    this.addPoint(point)
    this.handleMouseMove(event)
  }

  handleMouseMove = (event) => {
    if (!this.canvas || this.isErasing) return
    const mousePosition = getMousePosition(event)
    if (!this.isDrawing || !this.canvas) return
    const lastPoint = this.points[this.points.length - 1]
    const point = {
      x: remap(mousePosition.x, 0, this.canvas.width, 0, 1),
      y: remap(mousePosition.y, 0, this.canvas.height, 0, 1),
      t: Date.now(),
      tool: 'Pencil',
    }
    if (lastPoint && point.x === lastPoint.x && point.y === lastPoint.x) return
    this.lastAddition = Date.now()
    this.addPoint(point)
  }

  handleMouseUp = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!this.canvas || this.isErasing) return
    this.isPredicted = false
    this.isDrawing = false
    this.drawing.push(this.points)
    this.points = []

    store.dispatch(canvasChanged(this.lastAddition))


    // only start scanning the canvas when the drawing changed enough
    if (this.addedLength >= this.maxLineDist / 10) {
      this.addedLength = 0
      this.scanCanvas()
    } else {
      this.scanCanvas({ silent: true })
      store.dispatch(startTimer())
    }
  }

  handleMouseLeave = (event) => {
    this.handleMouseUp(event)
  }

  updateBounds = ({ sx = 0, sy = 0, w, h } = {}) => {
    const width = w || window.innerWidth
    const height = h || window.innerHeight
    const points = [...this.points, ...this.drawing.flat()].map(p => ({
      x: sx + p.x * width,
      y: sy + p.y * height,
    }))
    this.bounds = bounds(points)
  }

  addPoint = (point) => {
    const prevPoint = this.points[this.points.length - 1]
    if (prevPoint) {
      const distance = dist(prevPoint.x, prevPoint.y, point.x, point.y)
      this.lineDist += distance
      this.addedLength += distance
    }
    this.points.push(point)
    this.updateStrokes()
  }

  updateStrokes = () => {
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)
    this.drawing.concat([this.points]).forEach((stroke) => {
      ToolProxy.drawStroke(this.context, stroke)
    })
    this.updateBounds()

    if (this.lineDist >= this.maxLineDist) {
      if (!this.drawing.length) {
        while (this.lineDist >= this.maxLineDist) {
          const removedPoint = this.points.shift()
          const d = dist(removedPoint.x, removedPoint.y, this.points[0].x, this.points[0].y)
          this.lineDist -= d
          this.numPoints -= 1
        }
      } else {
        while (this.lineDist >= this.maxLineDist) {
          const removedPoint = [].concat(...this.drawing).length
            ? this.drawing.filter(stroke => stroke.length)[0].shift()
            : this.points.shift()
          const firstPoint = [].concat(...this.drawing.concat([this.points]))[0]
          const d = dist(removedPoint.x, removedPoint.y, firstPoint.x, firstPoint.y)
          this.lineDist -= d
          this.numPoints -= 1
        }
      }
    }
  }

  getSquareCanvas = (imageData) => {
    if (!imageData) return new Error()
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const { width, height } = imageData
    const landscape = width >= height
    const size = landscape ? width : height
    const x = landscape ? 0 : (height - width) / 2
    const y = landscape ? (width - height) / 2 : 0
    canvas.width = size
    canvas.height = size
    context.fillStyle = 'white'
    context.fillRect(0, 0, canvas.width, canvas.height)
    context.putImageData(imageData, x, y)
    return canvas
  }

  scanCanvas = ({ silent = false } = {}) => {
    const image = PredictImageProxy.drawImage(this.drawing.concat([this.points]))
    store.dispatch(scanCanvas({
      image,
      drawing: this.drawing,
      bounds: this.bounds,
      lastAddition: this.lastAddition,
      silent,
    }))
  }

  eraseCanvas = async () => {
    if (!this.context || !this.drawing.length || this.isErasing) return
    this.isErasing = true
    const eraserWidth = 70
    const strides = Math.ceil(Math.max((this.bounds.xMax - this.bounds.xMin) / eraserWidth, 3)) * 2
    const eraserPath = new Array(strides).fill('').map((e, i) => ({
      x: remap(i, strides, 0, this.bounds.xMin - eraserWidth, this.bounds.xMax + eraserWidth),
      y: i % 2 === 0 ? this.bounds.yMin - eraserWidth / 2 : this.bounds.yMax + eraserWidth / 2,
    }))

    await ToolProxy.eraseContext(this.context, this.eraserStartPosition, eraserPath)
    this.isErasing = false
    // NOTE: make sure we don't have any draw data left...
    this.points = []
    this.drawing = []
    this.addedLength = 0
    this.resetCanvas()
  }

  resetCanvas = () => {
    store.dispatch(resetCanvas())
    this.setInitialState()
  }

  removeListeners = () => {
    DocumentProxy.removeListener(window, 'resize', this.resizeCanvas)
    DocumentProxy.removeListener(this.canvas, ['mousedown', 'touchstart'], this.handleMouseDown)
    DocumentProxy.removeListener(this.canvas, ['mousemove', 'touchmove'], this.handleMouseMove)
    DocumentProxy.removeListener(this.canvas, ['mouseup', 'touchend'], this.handleMouseUp)
  }

  destroy = () => {
    this.removeListeners()
    this.resetCanvas()
    this.canvas = null
    this.context = null
    console.log('(CanvasProxy: destroy)')
  }
}

export default new CanvasProxy()
