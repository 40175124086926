import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import ReactGA from 'react-ga'
import '../scss/Subtitles.scss'
import { getLatestItem, getFirstItem } from '../selectors/scenario'
import { capitalize } from '../utils'

const {
  REACT_APP_OG_BASE_URL: OG_BASE_URL,
} = process.env

class Subtitles extends Component {
  // eslint-disable-next-line
  shareFacebook = (item) => {
    window.FB.ui({
      method: 'share',
      href: OG_BASE_URL,
    }, (response) => {
      if (response) {
        ReactGA.event({
          category: 'Share',
          action: 'Successful share on Facebook',
        })
      } else {
        ReactGA.event({
          category: 'Share',
          action: 'Unsuccessful share on Facebook',
        })
      }
    })

    ReactGA.event({
      category: 'Share',
      action: 'Opened Facebook share dialog',
    })
  }

  shareTwitter = (item) => {
    const message = encodeURIComponent(`“${capitalize(item.subtitles)}”\n${window.location.hostname}`)
    const target = encodeURIComponent(`${window.location.hostname}`)
    // const url = `https://twitter.com/intent/tweet?text=${message}&url=${target}&hashtags=donotdrawapenis,StudioMoniker,Mozilla,HKW&related=Mozilla,HKW,StudioMoniker`
    const url = `https://twitter.com/intent/tweet?text=${message}&url=${target}`
    const w = 550
    const h = 300
    const left = window.screen.width / 2 - w / 2
    const top = window.screen.height / 2 - h / 2 - 100
    window.open(url, 'twitter', `resizable=1,width=${w},height=${h},left=${left},top=${top}`)

    ReactGA.event({
      category: 'Share',
      action: 'Opened Twitter share dialog',
    })
  }

  render() {
    const subtitlesClass = classNames('Subtitles', {
      red: window.location.href.includes('colophon'),
    })
    const { item, innerRef } = this.props
    const text = item ? item.subtitles.replace(/\[category\]/gi, item.category) : ''

    return (
      <div className={subtitlesClass} ref={innerRef}>
        <div>{item && capitalize(text)}</div>
        {item && window.location.pathname.includes('colophon') && (
          <div className="buttons">
            <span
              className="button facebook"
              onMouseUp={() => this.shareFacebook(item)}
              onTouchStart={() => this.shareFacebook(item)}
            />
            <span
              className="button twitter"
              onMouseUp={() => this.shareTwitter(item)}
              onTouchStart={() => this.shareTwitter(item)}
            />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  item: getLatestItem(state)
    || (window.location.pathname.includes('colophon') && getFirstItem(state)),
})

const Connected = connect(mapStateToProps)(Subtitles)
export default React.forwardRef((props, ref) => <Connected innerRef={ref} {...props} />)
