// TODO: this should be a page to our store
import React from 'react'
import '../scss/ShopLink.scss'

const ShopLink = () => (
  <div className="ShopLink">
    Do you like these drawings?<br />Buy a{' '}
    <a className="link" href="https://shop.studiomoniker.com/product/do-not-draw-a-penis-tea-towel">
      tea towel
    </a>{' '}
    or{' '}
    <a className="link" href="https://shop.studiomoniker.com/product/do-not-draw-a-penis-face-mask">
      face mask
    </a> from our{' '}
    <a className="link" href="https://shop.studiomoniker.com">
      shop
    </a>.
  </div>
)

export default ShopLink
