/* eslint-disable object-curly-newline, no-await-in-loop */
import Vector from 'victor'
import device from 'current-device'
import { emitter, mid, remap, sleep, clip } from '../utils'
import settings from '../settings'

class ToolProxy {
  static instance
  tool
  pencilPosition
  eraserPosition

  constructor() {
    if (ToolProxy.instance) return ToolProxy.instance
    ToolProxy.instance = this
  }

  drawStroke = (context, stroke, sx = 0, sy = 0, w, h) => {
    if (stroke.length < 1) return new Error()
    w = w || context.canvas.width
    h = h || context.canvas.height
    let p1 = stroke[0]
    let p2 = stroke[1]

    if (p1.tool === 'Pencil') {
      context.strokeStyle = settings.colors.grey

      // lineTo width same start + end to work on Safari
      if (stroke.length > 1) {
        context.beginPath()
        context.moveTo(sx + p1.x * w, sy + p1.y * h)
        for (let i = 1, len = stroke.length; i < len; i += 1) {
          const { x, y } = mid(p1, p2)
          context.quadraticCurveTo(sx + p1.x * w, sy + p1.y * h, sx + x * w, sy + y * h)
          p1 = stroke[i]
          p2 = stroke[i + 1]
        }
        context.lineTo(sx + p1.x * w, sy + p1.y * h)
        context.stroke()
        context.closePath()
      } else {
        context.beginPath()
        context.arc(sx + p1.x * w, sy + p1.y * h, 1, 0, 2 * Math.PI)
        context.lineWidth -= 2
        context.stroke()
        context.lineWidth += 2
      }
    }

    if (p1.tool === 'Eraser') {
      // eslint-disable-next-line
      ;(p1 = stroke[0]), (p2 = stroke[1])
      const { lineWidth } = context
      context.beginPath()
      context.strokeStyle = '#fff'
      if (!device.desktop()) {
        sx += 25
        sy += 25
      }
      context.lineWidth = 150
      for (let i = 1, len = stroke.length; i < len; i += 1) {
        context.save()
        context.moveTo(sx + p2.x * w, sy + p2.y * h)
        context.lineTo(sx + p1.x * w, sy + p1.y * h)
        context.restore()
        p1 = stroke[i]
        p2 = stroke[i + 1]
      }
      context.fill()
      context.stroke()
      context.closePath()
      context.lineWidth = lineWidth
    }
  }

  drawRoundRect = (context, sx, sy, ex, ey, r) => {
    const r2d = Math.PI / 180
    if (ex - sx - 2 * r < 0) r = (ex - sx) / 2
    if (ey - sy - 2 * r < 0) r = (ey - sy) / 2
    context.beginPath()
    context.moveTo(sx + r, sy)
    context.lineTo(ex - r, sy)
    context.arc(ex - r, sy + r, r, r2d * 270, r2d * 360, false)
    context.lineTo(ex, ey - r)
    context.arc(ex - r, ey - r, r, r2d * 0, r2d * 90, false)
    context.lineTo(sx + r, ey)
    context.arc(sx + r, ey - r, r, r2d * 90, r2d * 180, false)
    context.lineTo(sx, sy + r)
    context.arc(sx + r, sy + r, r, r2d * 180, r2d * 270, false)
    context.closePath()
  }

  eraseContext = async (context, startPos, points) => {
    this.context = context
    this.eraserPoints = []
    this.eraserPosition = Vector.fromObject(startPos)
    this.eraserVelocity = new Vector(0, 0)

    await this.animateEraserTo(points[0])
    for (let i = 1; i < points.length; i += 1) {
      if (i === 1) await sleep(100 + Math.random() * 200)
      else await sleep(Math.random() * 25)
      await this.animateEraserTo(points[i])
    }

    emitter.emit('erased')
    await sleep(100 + Math.random() * 400)
    await this.animateEraserTo(startPos)
  }

  animateEraserTo = (pos, easeTime = 300) =>
    new Promise((resolve) => {
      this.eraserPoints = []
      this.eraserTarget = Vector.fromObject(pos)
      this.eraserVelocity.multiply(new Vector(0.05, 0.05))
      if (this.eraserAnimation) cancelAnimationFrame(this.eraserAnimation)

      const animationStartTime = new Date().getTime()
      const animationCurrentTime = () => new Date().getTime() - animationStartTime
      this.animationPercent = () => clip(animationCurrentTime() / easeTime, 0, 1)

      this.eraserAnimation = requestAnimationFrame(() => this.update(resolve))
    })

  update = (cb) => {
    const animationPercent = this.animationPercent()

    const easeInOutCubic = t => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1)
    const positionPercent = easeInOutCubic(animationPercent)

    const d = this.eraserTarget.clone().subtract(this.eraserPosition)
    const updatedPosition = this.eraserPosition.add(d.multiplyScalar(positionPercent))
    this.eraserPosition = updatedPosition.clone()

    emitter.emit('eraserPosition', this.eraserPosition.clone().toObject())

    if (this.eraserTarget.distance(this.eraserPosition) < 25) return cb()

    this.eraserAnimation = requestAnimationFrame(() => this.update(cb))

    const point = {
      x: remap(this.eraserPosition.x, 0, this.context.canvas.width, 0, 1),
      y: remap(this.eraserPosition.y, 0, this.context.canvas.height, 0, 1),
      t: Date.now(),
      tool: 'Eraser',
    }
    this.eraserPoints.push(point)
    this.drawStroke(this.context, this.eraserPoints)
  }
}

export default new ToolProxy()
