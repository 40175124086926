import uuidv4 from 'uuid/v4'
import constants from '../actions/constants'

const savedState = { user: {} }

const initialState = {
  uuid: savedState.user.uuid || uuidv4(),
  level: savedState.user.level || 0,
  warnings: savedState.user.warnings || 0,
  prevUpdate: Date.now(),
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LEVEL_UP:
      return {
        ...state,
        prevUpdate: Date.now(),
        level: action.data ? action.data : state.level + 1,
      }

    case constants.WARN_USER:
      return {
        ...state,
        warnings: action.data ? state.warnings + action.data : state.warnings + 1,
      }

    case constants.FINISHED:
      return {
        ...state,
        hasFinished: true,
      }

    case constants.RESET_USER:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
