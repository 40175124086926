/* eslint-disable max-len */
import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import classNames from 'classnames'
import Tappable from 'react-tappable'
import { TweenMax, Elastic, Sine } from 'gsap/TweenMax'

import '../scss/Eraser.scss'
import { startEraseCanvas } from '../actions/canvas'
import { emitter } from '../utils'
import DocumentProxy from '../proxies/DocumentProxy'
import CanvasProxy from '../proxies/CanvasProxy'

class Eraser extends Component {
  state = {
    x: CanvasProxy.eraserStartPosition.x,
    y: CanvasProxy.eraserStartPosition.y,
    mouseHover: false,
  }

  componentDidMount() {
    emitter.on('eraserPosition', this.handleEraserMove)
    DocumentProxy.addListener(window, 'resize', this.handleResize)
  }

  componentWillUnmount() {
    emitter.off('eraserPosition', this.handleEraserMove)
    DocumentProxy.removeListener(window, 'resize', this.handleResize)
  }

  onMouseOver = () => this.setState({ mouseHover: true })

  onMouseOut = () => this.setState({ mouseHover: false })

  handleEraserMove = ({ x, y }) => this.setState({ x, y })

  handleResize = () => this.setState(CanvasProxy.eraserStartPosition)

  shake = () => {
    const pos = {
      x: this.state.x,
      y: this.state.y,
    }

    const onUpdate = () => {
      this.setState({ x: pos.x, y: pos.y })
    }

    const tween = TweenMax.fromTo(
      pos, 0.05, { x: pos.x },
      {
        x: pos.x + 10,
        repeat: 5,
        yoyo: true,
        ease: Sine.easeInOut,
        onComplete: () => {
          TweenMax.to(tween.target, 0.1, { x: this.state.x, ease: Elastic.easeOut, onUpdate })
        },
        onUpdate,
      },
    )
  }

  startErasing = () => {
    const { erasing } = this.props
    if (!erasing && CanvasProxy.drawing.length > 0) {
      ReactGA.event({ category: 'Eraser', action: 'Start' })
      this.props.startEraseCanvas()
    } else if (!erasing) {
      this.shake()
      ReactGA.event({ category: 'Eraser', action: 'No drawing' })
    }
  }

  render() {
    const { erasing } = this.props
    const { x, y, mouseHover } = this.state
    const eraserClass = classNames('Eraser', { erasing })

    return (
      <Tappable
        className={eraserClass}
        draggable={false}
        style={{
          '--x': `${x - 150}px`,
          '--y': `${y - 175}px`,
          '--scale': mouseHover ? 1.03 : 1,
        }}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onTap={this.startErasing}
      />
    )
  }
}

const mapStateToProps = state => ({
  erasing: state.canvas.erasing,
})

const mapDispatchToProps = {
  startEraseCanvas,
}

export default connect(mapStateToProps, mapDispatchToProps)(Eraser)
