import constants from '../actions/constants'

const initialState = {
  initialized: false,
  image: undefined,
  predictions: [],
  time: undefined,
  error: undefined,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_MODEL_SUCCESS:
      return {
        ...state,
        initialized: true,
      }

    case constants.LOAD_MODEL_ERROR:
      return {
        ...state,
        error: constants.LOAD_MODEL_ERROR,
      }

    case constants.CREATE_PREDICTION:
      return {
        ...state,
        image: action.data,
      }

    case constants.CREATE_MULTI_PREDICTION_SUCCESS:
      return {
        ...state,
        ...action.data,
      }

    case constants.RESET_MODEL:
      return {
        ...initialState,
        initialized: true,
      }

    default:
      return state
  }
}
