import app from './app'
import user from './user'
import model from './model'
import canvas from './canvas'
import scenario from './scenario'

export default {
  app,
  user,
  model,
  canvas,
  scenario,
}
