import React from 'react'
import { connect } from 'react-redux'
import '../scss/Canvas.scss'

import Canvas from './Canvas'

const CanvasHolder = ({ initialized }) => (
  <div
    className="Canvas"
    style={{
      '--pointerEvents': initialized ? 'none' : 'initial',
    }}
  >
    <Canvas />
  </div>
)

const mapStateToProps = state => ({
  initialized: state.model.initialized,
})

export default connect(mapStateToProps)(CanvasHolder)
