import React from 'react'

const Mozilla = () => (
  <svg viewBox="0 0 360 102.8" className="mozilla">
    <path d="M0-.2h360v102.9H0z" />
    <path
      d="M343.8 73.7c-.7.2-1.3.3-1.9.3-2.1 0-3.1-.9-3.1-3.5V51c0-10.3-8.2-15.3-17.9-15.3-7.4 0-11.4.9-19.3 4.1L300 50.2l10.3 1.1 1.5-5.1c2.1-1.1 4.2-1.3 6.9-1.3 7.3 0 7.4 5.5 7.4 10.1v1.5c-2.3-.3-4.9-.4-7.4-.4-10.3 0-21 2.6-21 13.7 0 9.4 7.4 12.9 13.9 12.9 7.3 0 11.9-4.4 14.5-9 .6 5.5 3.9 9 9.9 9 2.8 0 5.7-.8 8.1-2.1l-.3-6.9zm-27.6-.3c-3.9 0-5.3-2.3-5.3-5.2 0-4.9 4-6.2 8.6-6.2 2.1 0 4.4.3 6.5.6-.3 7.5-5.2 10.8-9.8 10.8zm-17.3-60.5l-19.5 68.7h-12.7l19.5-68.7h12.7zm-26.2 0l-19.5 68.7h-12.7L260 12.9h12.7zm-54 23.7h13.5v16.2h-13.5V36.6zm0 28.9h13.5v16.2h-13.5V65.5zm-19.7-.6l10.2 1-2.8 15.8h-39.3l-1.3-6.8 24.8-28.5h-14.1l-2 7-9.3-1 1.6-15.8h39.5l1 6.8-25 28.5h14.6l2.1-7zm-64.5-29.2c-16.2 0-24.2 10.9-24.2 24.1 0 14.4 9.6 22.9 23.5 22.9 14.4 0 24.8-9.1 24.8-23.5 0-12.6-7.9-23.5-24.1-23.5zm-.3 37c-7 0-10.6-6-10.6-13.8 0-8.5 4.1-13.4 10.7-13.4 6.1 0 11 4.1 11 13.2 0 8.7-4.4 14-11.1 14zm-35.5-.9h6v9.8H85.9V56.3c0-7.8-2.6-10.8-7.7-10.8-6.2 0-8.7 4.4-8.7 10.7v15.7h6v9.8H56.6V56.3c0-7.8-2.6-10.8-7.7-10.8-6.2 0-8.7 4.4-8.7 10.7v15.7h8.6v9.8H21.4v-9.8h6V46.4h-6v-9.8h18.9v6.8c2.7-4.8 7.4-7.7 13.7-7.7 6.5 0 12.5 3.1 14.7 9.7 2.5-6 7.6-9.7 14.7-9.7 8.1 0 15.5 4.9 15.5 15.6v20.5z"
      fill="#fff"
    />
  </svg>
)

export default Mozilla
