import ReactGA from 'react-ga'
import sample from 'lodash.sample'
import copy from '../resources/copy.json'

/* eslint-disable no-unused-vars, no-use-before-define, object-curly-newline */
import constants from './constants'
import SpeechProxy from '../proxies/SpeechProxy'
import AutoDrawProxy from '../proxies/AutoDrawProxy'
import { startEraseCanvas } from './canvas'
import { saveUser, warnUser, levelUp } from './user'
import {
  getTimedCopyItems,
  getUntimedCopyItems,
  getLevelUpItems,
  getCurrentLevelCopyItems,
} from '../selectors/scenario'
import { sleep, getAudioFilename, emitter } from '../utils'
import settings from '../settings'
import { showWarning } from './app'

const atEndLevel = (user, scenario) => user.level >= scenario.levels

export const loadScenario = () => async (dispatch, getState) => {
  await dispatch({ type: constants.LOAD_SCENARIO, data: copy })
  dispatch(tick())
}

// checking if we're ready for new copy
export const isReadyForCopy = (copy = []) => (dispatch, getState) => {
  const { app, user, scenario } = getState()
  // Always stop item after 15 sec
  const timeSinceScenarioUpdate = Date.now() - scenario.prevUpdate
  if (timeSinceScenarioUpdate > settings.maximumCopyTime) dispatch(stopCopyItem())

  if (copy.length === 0) return false
  if (atEndLevel(user, scenario)) return true
  if (scenario.item || app.paused) return false
  return true
}

const warningPrediction = (copy = [], canvasTimestamp) => async (dispatch, getState) => {
  // Start playing copy for this level warning
  const levelUpItem = getLevelUpItems(getState()).pop()
  dispatch(startCopyItem(copy, { canvasTimestamp, levelUpItem }))

  // Progress to next level
  dispatch(levelUp())

  const { user, scenario } = getState()
  const { level } = user

  dispatch(showWarning())
  // don't count warning until after first level
  if (level > 1 && !atEndLevel(user, scenario)) {
    await dispatch(warnUser())
  }

  if (atEndLevel(user, scenario)) {
    await dispatch({ type: constants.FINISHED })
    dispatch(startEndScenario())
  }
}

const normalPrediction = (copy = [], canvasTimestamp) => async (dispatch, getState) => {
  const state = getState()
  const { user } = state

  if (user.level === 0) {
    dispatch(levelUp())
  }

  dispatch(startCopyItem(copy, { canvasTimestamp }))
}
// Maybe should not have copy as argument, but read from state
export const reactOnPredictions = (copy = [], canvasTimestamp) => async (dispatch, getState) => {
  const isReady = await dispatch(isReadyForCopy(copy))
  if (!isReady) return

  dispatch(stopTimer())
  const isWarning = copy[0].warning

  //  level up in the first/intro level or when there's a warning
  // don't level up in the last level

  if (isWarning) {
    await dispatch(warningPrediction(copy, canvasTimestamp))
  } else {
    await dispatch(normalPrediction(copy, canvasTimestamp))
  }
  // store the current state of the user
  dispatch(saveUser())
}

export const onCopyStarted = (item, canvasTimestamp) => (dispatch) => {
  ReactGA.pageview(`/copy/${getAudioFilename(item.text)}`, [], item.text)
  dispatch({
    type: constants.START_COPY_ITEM,
    data: {
      item,
      canvasTimestamp,
    },
  })

  dispatch(saveUser())
}

export const startCopyItem = (items = [], { canvasTimestamp, levelUpItem } = {}) => async (
  dispatch,
  getState,
) => {
  let item = sample(items)
  if (!item) return

  const isReady = await dispatch(isReadyForCopy(items))
  if (!isReady) return

  const state = getState()
  const { user, scenario } = state

  // we want to play a level up message before the actual warning copy
  let nextAudioFile
  // NOTE: this only works because we only have 1 warning per level
  // we need to make sure we have a warning item and it's not the last level
  if (item.warning && levelUpItem) {
    nextAudioFile = item
    item = levelUpItem
  }

  if (item.warning && !levelUpItem && !atEndLevel(user, scenario)) {
    const erase = new Promise((resolve) => {
      sleep(250 + Math.random() * 750)
        .then(() => dispatch(startEraseCanvas()))
        .then(resolve)
    })
  }

  // a copy item can be related to a timestamp which represents the last change to the canvas
  // this connection tells us if the copy already repsonded to the current state of the canvas
  // in case there's no associated state/timestamp we reset the silence threshold for timed copy
  if (canvasTimestamp) {
    dispatch({ type: constants.SET_SILENCE_THRESHOLD, data: settings.defaultSilenceThreshold })
  }
  SpeechProxy.say(
    item,
    () => {
      dispatch(onCopyStarted(item, canvasTimestamp))
    },
    async () => {
      dispatch(stopCopyItem())
      if (nextAudioFile) {
        dispatch(startCopyItem([nextAudioFile]))
      }
    },
  )
}

export const stopCopyItem = () => async (dispatch, getState) => {
  const state = getState()
  const { scenario, user } = state
  if (!scenario.item || SpeechProxy.isPlaying) return
  await dispatch({ type: constants.STOP_COPY_ITEM })
  if (!atEndLevel(user, scenario)) {
    dispatch(startTimer())
  }

  emitter.emit('copyStopped')
}

export const startTimer = () => (dispatch) => {
  dispatch({ type: constants.START_TIMER })
  copyDone = Date.now()
  isAfterCopy = false
}

export const stopTimer = () => (dispatch) => {
  dispatch({ type: constants.STOP_TIMER })
}

let isAfterCopy = false
let copyDone = Date.now()

const reactOnTick = () => async (dispatch, getState) => {
  dispatch({ type: constants.TICK })
  const { scenario, canvas } = getState()
  const { silenceThreshold, canvasTimestamp, timerStart } = scenario

  const copyPlaying = scenario.timerStart === undefined
  if (copyPlaying) return

  const newDrawingSinceLastResponse = canvas.lastAddition !== canvasTimestamp

  const elapsed = Date.now() - timerStart

  let debounceTime = settings.newDrawingResponseInterval
  if (isAfterCopy) {
    debounceTime = settings.debounceHitDelay
  } else {
    isAfterCopy = Date.now() - copyDone > settings.newDrawingResponseInterval
  }

  const silentAndNewDrawing = newDrawingSinceLastResponse && elapsed > debounceTime

  const silentAndSameDrawing = !newDrawingSinceLastResponse && elapsed > silenceThreshold

  if (silentAndNewDrawing) {
    dispatch(reactOnPredictions(getUntimedCopyItems(getState()), canvas.lastAddition))
  }

  // Respond to old drawing if it has existed for x seconds. Plays 'timed' copy
  if (silentAndSameDrawing) {
    const timedItems = getTimedCopyItems(getState())
    if (timedItems.length) {
      // in case we have copy items to play
      dispatch(startCopyItem(timedItems))
      dispatch(stopTimer())
      // also increase wait time for timed copy items
      const newSilenceThreshold = settings.newSilenceThreshold(silenceThreshold)
      dispatch({ type: constants.SET_SILENCE_THRESHOLD, data: newSilenceThreshold })
    }
  }
}

export const tick = () => async (dispatch, getState) => {
  const { app, scenario, user } = getState()
  if (!app.paused) {
    await dispatch(reactOnTick())
  }
  await sleep(settings.tickInterval)
  if (!atEndLevel(user, scenario)) requestAnimationFrame(() => dispatch(tick()))
}

export const resetScenario = () => (dispatch, getState) => {
  dispatch({ type: constants.RESET_SCENARIO })
}

export const startFinalCopyItems = () => (dispatch, getState) => {
  const copyItems = getCurrentLevelCopyItems(getState())

  const augmented = copyItems.map(c => (c.hasCategory ? { ...c, category: sample(settings.penisReplacements) } : c))
  dispatch(startCopyItem(augmented))
}

export const startEndScenario = () => (dispatch) => {
  emitter.emit('startEndScenario')
  let finishedDrawings = 0
  AutoDrawProxy.start()

  let insultTimer = Date.now()
  let interval = 5000

  const playCopy = () => {
    const elapsed = Date.now() - insultTimer
    if (!SpeechProxy.isPlaying && elapsed > interval) {
      insultTimer = Date.now()
      dispatch(startFinalCopyItems())
      interval = settings.newSilenceThreshold(interval)
    }
    setTimeout(playCopy.bind(this), 1000)
  }

  playCopy()

  emitter.on('autoDrawingFinished', () => {
    setTimeout(() => {
      AutoDrawProxy.startErasing()
      finishedDrawings += 1
      if (finishedDrawings === 10) {
        dispatch({ type: constants.TOGGLE_SHOP_LINK })
      }
    }, 1000)
  })

  // we want to initialise the next drawing when the eraser is on the way back
  emitter.on('erased', () => AutoDrawProxy.nextDrawing())
}

export const debugEndScenario = () => async (dispatch, getState) => {
  await dispatch(resetScenario())
  await dispatch(loadScenario())
  const { scenario } = getState()

  await dispatch({ type: constants.LEVEL_UP, data: scenario.levels })
  dispatch(startEndScenario())
}
