import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'

import '../scss/Share.scss'
import { getLatestItem, getFirstItem } from '../selectors/scenario'
import { capitalize } from '../utils'

const { REACT_APP_OG_BASE_URL: OG_BASE_URL } = process.env

class Share extends Component {
  shareFacebook = () => {
    window.FB.ui(
      {
        method: 'share',
        href: OG_BASE_URL,
      },
      (response) => {
        if (response) {
          ReactGA.event({
            category: 'Share',
            action: 'Successful share on Facebook',
          })
        } else {
          ReactGA.event({
            category: 'Share',
            action: 'Unsuccessful share on Facebook',
          })
        }
      },
    )

    ReactGA.event({
      category: 'Share',
      action: 'Opened Facebook share dialog',
    })
  }

  shareTwitter = (item) => {
    const replaced = item.subtitles.replace(/\[category\]/gi, item.category)
    const message = `“${capitalize(replaced)}”\n${window.location.hostname}`
    const encoded = encodeURIComponent(message)
    const target = encodeURIComponent(`${window.location.hostname}`)
    // const url = `https://twitter.com/intent/tweet?text=${message}&url=${target}&hashtags=donotdrawapenis,StudioMoniker,Mozilla,HKW&related=Mozilla,HKW,StudioMoniker`
    const url = `https://twitter.com/intent/tweet?text=${encoded}&url=${target}`
    const w = 550
    const h = 300
    const left = window.screen.width / 2 - w / 2
    const top = window.screen.height / 2 - h / 2 - 100
    window.open(url, 'twitter', `resizable=1,width=${w},height=${h},left=${left},top=${top}`)

    ReactGA.event({
      category: 'Share',
      action: 'Opened Twitter share dialog',
    })
  }

  render() {
    const { item } = this.props
    const text = item ? item.subtitles.replace(/\[category\]/gi, item.category) : ''

    return (
      <div className="Share">
        <div>{item && capitalize(text)}</div>
        <div className="buttons">
          <span
            className="button facebook"
            onMouseUp={() => this.shareFacebook(item)}
            onTouchStart={() => this.shareFacebook(item)}
          />
          <span
            className="button twitter"
            onMouseUp={() => this.shareTwitter(item)}
            onTouchStart={() => this.shareTwitter(item)}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  item: getLatestItem(state) || getFirstItem(state),
})

export default connect(mapStateToProps)(Share)
