/* eslint-disable no-use-before-define, object-curly-newline */
import ReactGA from 'react-ga'
import Raven from 'raven-js'
import { push } from 'react-router-redux'

import constants from './constants'
import settings from '../settings'
import { reactOnPredictions } from './scenario'
import { getUntimedCopyItems } from '../selectors/scenario'
import ModelProxy from '../proxies/ModelProxy'

export const loadModel = () => async (dispatch) => {
  dispatch({ type: constants.LOAD_MODEL })
  const start = performance.now()

  try {
    const data = await ModelProxy.init()
    dispatch({ type: constants.LOAD_MODEL_SUCCESS, data })
  } catch (error) {
    dispatch({ type: constants.LOAD_MODEL_ERROR, data: error })
    dispatch(push('/error'))
    Raven.captureException(error)
  }

  if (settings.isDevMode) return
  ReactGA.timing({
    category: 'Model',
    variable: 'Load model',
    value: performance.now() - start,
    nonInteraction: true,
  })
}

// a prediction is always made based on the state of the canvas on a specific time
// lastAddition is the time when the change to the canvas took place
export const createPrediction = ({ silent = false, lastAddition }) => async (
  dispatch,
  getState,
) => {
  const { app, model, canvas } = getState()
  if (!model.initialized || app.paused || canvas.image === canvas.prevImage) return

  dispatch({ type: constants.CREATE_PREDICTION, data: canvas.image })
  const start = performance.now()

  try {
    const data = await ModelProxy.predictMulti(canvas.image)
    dispatch({ type: constants.CREATE_MULTI_PREDICTION_SUCCESS, data })
  } catch (error) {
    Raven.captureException(error)
    dispatch({ type: constants.CREATE_MULTI_PREDICTION_ERROR, data: error })
  }

  if (!silent) {
    const copy = getUntimedCopyItems(getState())
    dispatch(reactOnPredictions(copy, lastAddition))
  }

  if (settings.isDevMode) return
  ReactGA.timing({
    category: 'Model',
    variable: 'Predict',
    value: performance.now() - start,
    nonInteraction: true,
  })
}

export const resetModel = () => (dispatch, getState) => {
  const { model } = getState()
  if (!model.initialized) return
  dispatch({ type: constants.RESET_MODEL })
}
