import keyMirror from 'keymirror'

export default keyMirror({
  // App actions
  INIT_APP: null,
  READY_APP: null,
  PAUSE_APP: null,
  RESUME_APP: null,
  HIDE_INTRO_SCREEN: null,
  TOGGLE_MUTE: null,
  SHOW_STATUS: null,
  TOGGLE_DEBUG: null,
  RESET_APP: null,
  TOGGLE_SHOP_LINK: null,
  SHOW_WARNING: null,
  HIDE_WARNING: null,

  // Model actions
  LOAD_MODEL: null,
  LOAD_MODEL_SUCCESS: null,
  LOAD_MODEL_ERROR: null,
  CREATE_PREDICTION: null,
  CREATE_MULTI_PREDICTION_SUCCESS: null,
  CREATE_MULTI_PREDICTION_ERROR: null,
  RESET_MODEL: null,

  // Canvas actions
  SCAN_CANVAS: null,
  START_ERASE_CANVAS: null,
  STOP_ERASE_CANVAS: null,
  RESET_CANVAS: null,
  CANVAS_CHANGED: null,

  // User actions
  UPDATE_USER: null,
  LEVEL_UP: null,
  WARN_USER: null,
  SAVE_USER: null,
  RESET_USER: null,
  FINISHED: null,

  // Scenario actions
  LOAD_SCENARIO: null,
  START_COPY_ITEM: null,
  STOP_COPY_ITEM: null,
  TICK: null,
  RESET_SCENARIO: null,
  START_TIMER: null,
  STOP_TIMER: null,
  SET_SILENCE_THRESHOLD: null,
})
