import 'react-app-polyfill/ie11'
import 'core-js/es6/map'
import 'core-js/es6/set'
import 'raf/polyfill'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import { Route } from 'react-router-dom'
import ReactGA from 'react-ga'
import Raven from 'raven-js'

import './scss/_index.scss'
import settings from './settings'
import { store, history } from './store'
import App from './components/App'

console.log('commit', process.env.REACT_APP_GIT_SHA)

// detect Safari browsers, used for underline adjustments
const isSafari =
  navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1
if (isSafari) document.documentElement.classList.add('safari')

const { REACT_APP_GA_TAG_ID: GA_TAG_ID, REACT_APP_SENTRY_DSN: SENTRY_DSN } = process.env

if (!window.location.origin.includes('localhost')) {
  ReactGA.initialize(GA_TAG_ID, { debug: settings.isDevMode })
  ReactGA.set({ anonymizeIp: true })
  Raven.config(SENTRY_DSN).install()
}

const Router = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Route component={App} />
    </ConnectedRouter>
  </Provider>
)

render(<Router />, document.getElementById('root'))
