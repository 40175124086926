/* eslint-disable
  no-undef,
  one-var,
  one-var-declaration-per-line,
  no-sequences,
  no-unused-expressions,
  prefer-destructuring,
  default-case,
  prefer-const,
  no-multi-assign,
  object-curly-newline
*/

import React from 'react'
import mitt from 'mitt'
import SecureLS from 'secure-ls'

export const noop = () => { }

export const emitter = mitt()

export const storage = new SecureLS()

export const remap = (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c

export const mid = ({ x: x1, y: y1 }, { x: x2, y: y2 }) => ({
  x: x1 + (x2 - x1) / 2,
  y: y1 + (y2 - y1) / 2,
})

export const dist = (x1, y1, x2, y2) => Math.hypot(x2 - x1, y2 - y1)

export const random = (min, max) => min + Math.random() * (max - min)

export const vowel = (str, a, b) => 'aeiouy'.indexOf(str.charAt(0)) > -1 ? a : b

export const capitalize = str => str.substring(0, 1).toUpperCase() + str.substring(1)

export const getAudioFilename = (e, ext = 'mp3') => `${e
  .replace(/'/g, '')
  .split(/[^aA-zZ;]/g)
  .filter(Boolean)
  .slice(0, 10)
  .join('-')}.${ext}`

// should always return an array
export const getAudioFilenames = ({ text, hasCategory, category }) => {
  if (!hasCategory) return [getAudioFilename(text)]
  const invalidChars = ['.', '...', '?', '!', ',', ';', ':', '"', "'"]

  const categoryFilename = getAudioFilename(`${category}-secure`)
  const parts = text.split('[category]')
    .reduce((acc, cur) => {
      acc.push(cur)
      acc.push(categoryFilename)
      return acc
    }, [])
    .filter(c => (Boolean(c) && !invalidChars.includes(c.trim())))
    .slice(0, -1)

  let count = 0
  const filenames = parts.map(part => part === categoryFilename
    ? part
    : getAudioFilename(text).replace('.mp3', `-${count += 1}.mp3`))

  return filenames
}

export const getMousePosition = ({ touches, clientX, clientY }) => ({
  x: touches ? parseInt(touches[0].clientX) : parseInt(clientX),
  y: touches ? parseInt(touches[0].clientY) : parseInt(clientY),
})

export const inBetween = (x, [a, b]) => x >= a && x < b

export const a = (text, href) => <a href={href} target="_blank" rel="noopener noreferrer">{text}</a>

export const cssVars = vars => Object.keys(vars)
  .reduce((acc, curr) => ({ ...acc, [`--${curr}`]: vars[curr] }), {})

export const shuffle = (v) => {
  let j, x, i
  for (i = v.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1))
    x = v[i]
    v[i] = v[j]
    v[j] = x
  }
  return v
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export const rainbow = (nh, ns = 1, nv = 1) => ((h, s, v) => {
  let r, g, b, i, f, p, q, t
  if (arguments.length === 1) s = h.s, v = h.v, h = h.h
  i = Math.floor(h * 6)
  f = h * 6 - i
  p = v * (1 - s)
  q = v * (1 - f * s)
  t = v * (1 - (1 - f) * s)
  switch (i % 6) {
    case 0: r = v, g = t, b = p; break
    case 1: r = q, g = v, b = p; break
    case 2: r = p, g = v, b = t; break
    case 3: r = p, g = q, b = v; break
    case 4: r = t, g = p, b = v; break
    case 5: r = v, g = p, b = q; break
  }
  return `rgb(${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(b * 255)})`
})(n / 100.0 * 0.85, ns, nv)

export const bounds = (points) => {
  const x = points.map(p => p.x)
  const y = points.map(p => p.y)
  return {
    xMin: Math.min(...x),
    xMax: Math.max(...x),
    yMin: Math.min(...y),
    yMax: Math.max(...y),
  }
}

export const clip = (number, min, max) => Math.max(min, Math.min(number, max))
