import { store } from '../store'
import { toggleMute, showDebug, resetApp } from '../actions/app'
import { warnUser, levelUp } from '../actions/user'
import { emitter, getMousePosition } from '../utils'
// import SpeechProxy from './SpeechProxy'

class DocumentProxy {
  static instance
  mouseMoved = false

  constructor() {
    if (DocumentProxy.instance) return DocumentProxy.instance
    DocumentProxy.instance = this
  }

  init = () => {
    this.addGlobalListeners()
    console.log('(DocumentProxy: init)')
  }

  addGlobalListeners = () => {
    this.addListener(document, 'keydown', this.registerKeydown)
    this.addListener(document, ['mousemove', 'touchmove'], this.registerMouseOrTouchMove)
  }

  removeGlobalListeners = () => {
    this.removeListener(document, 'keydown', this.registerKeydown)
    this.removeListener(document, ['mousemove', 'touchmove'], this.registerMouseOrTouchMove)
  }

  addListener = (element, events, fn) => {
    if (!element) return
    events = Array.isArray(events) ? events : [events]
    events.forEach((ev) => {
      if (typeof ev === 'string' && typeof fn === 'function') {
        element.addEventListener(ev, fn)
      } else {
        console.error('Could not listen to', ev, 'with handler', fn)
        if (typeof ev !== 'string') throw new Error(`Can not listen to event ${ev}`)
        if (typeof fn !== 'function') throw new Error('Handler must be a function')
      }
    })
  }

  removeListener = (element, events, fn) => {
    if (!element) return
    events = Array.isArray(events) ? events : [events]
    events.forEach(ev => element.removeEventListener(ev, fn))
  }

  registerKeydown = (event) => {
    const { origin } = window.location
    if (event.key === 'd') store.dispatch(showDebug())
    if (!(origin.includes('localhost') || origin.includes('staging'))) return
    if (event.key === 'm') store.dispatch(toggleMute())
    if (event.key === 'r') store.dispatch(resetApp())
    if (parseInt(event.key)) store.dispatch(levelUp(parseInt(event.key)))
    if (event.key === '-') store.dispatch(warnUser(-1))
    if (event.key === '=') store.dispatch(warnUser(1))
  }

  registerMouseOrTouchMove = (event) => {
    this.mouseMoved = true
    this.mousePosition = getMousePosition(event)
    emitter.emit('mousePosition', this.mousePosition)
  }

  destroy = () => {
    this.removeGlobalListeners()
    console.log('(DocumentProxy: destroy)')
  }
}

export default new DocumentProxy()
