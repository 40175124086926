/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import Tappable from 'react-tappable'

import '../scss/Colophon.scss'
import { pauseApp, resetApp, toggleMute, showDebug } from '../actions/app'

import Share from './Share'
import Nav from './Nav'
import HKW from './logos/HKW'
import Mozilla from './logos/Mozilla'

class Colophon extends Component {
  componentWillMount() {
    if (!this.props.paused) this.props.pauseApp()
  }

  render() {
    const colophonClass = classNames('Colophon', {})
    const { debugShowing } = this.props
    return (
      <Fragment>
        <div className={colophonClass}>
          <Share />
          <div className="content">
            <section className="text">
              <p>Do not draw a penis?</p>
              <p>
                In 2018 Google open-sourced the{' '}
                <a href="https://quickdraw.withgoogle.com/data">Quickdraw data set</a>. &ldquo;The
                world&apos;s largest doodling data set&rdquo;. The set consists of 345 categories
                and over 15 million drawings. For obvious reasons the data set was missing a few
                specific categories that people enjoy drawing. This made us at{' '}
                <a href="https://studiomoniker.com/">Moniker</a> think about the moral reality big
                tech companies are imposing on our global community and that most people willingly
                accept this. Therefore we decided to publish an appendix to the Google Quickdraw
                data set.
              </p>
              <p>
                Do Not Draw a Penis functions as an agent to collect inappropriate doodles from
                people who are not willing to stay within the moral guidelines set by our social
                network providers.
              </p>
              <p>
                So far we have collected 25,000 doodles formatted the same way as Google&apos;s
                dataset. We are happy to announce you can download them{' '}
                <a href="https://github.com/studiomoniker/Quickdraw-appendix">&#x2198;&nbsp;here</a>
                .
              </p>
              <p>
                As this website lives on we continue to collect doodles... and we will update the
                set.
              </p>
              <p>
                If you want to support this cause, order{' '}
                <a href="https://shop.studiomoniker.com/product/do-not-draw-a-penis-tea-towel">
                  our tea towel
                </a>{' '}
                or{' '}
                <a href="https://shop.studiomoniker.com/product/do-not-draw-a-penis-face-mask">face mask</a>{' '}
                featuring 5k unique penises drawn by naughty people from all over the globe.
              </p>
              <p className="caption">
                <img className="tea-towel" src="/assets/images/tea_towel.jpg" alt="" />
                Buy our tea towel{' '}
                <a href="https://shop.studiomoniker.com/product/do-not-draw-a-penis-tea-towel">
                  &#x2198;&nbsp;here
                </a>
              </p>
              <p className="caption">
                <img className="tea-towel" src="/assets/images/face-mask.jpg" alt="" />
                Buy our face mask{' '}
                <a href="https://shop.studiomoniker.com/product/do-not-draw-a-penis-face-mask">
                  &#x2198;&nbsp;here
                </a>
              </p>
              <p className="presskit">
                <img className="presskit-icon" src="/assets/images/presskit_icon.svg" alt="" />
                <a href="https://www.dropbox.com/sh/eln5vacukt7r8sv/AABHrZSCnNCz66t00VjJK0Pla?dl=1">
                  Download our presskit
                </a>
              </p>

              <p>
                Technologies
                <br />
                We use machine learning to recognize whether your doodles are suitable for the
                dataset or not. If you want to see what the model sees when you are drawing press{' '}
                <Tappable
                  draggable={false}
                  onTap={this.props.showDebug}
                  className={`button ${debugShowing ? 'debug-on' : 'debug-off'}`}
                />
                .
              </p>

              <p>
                Data collection
                <br />
                We will upload your drawing data the moment our model puts the &ldquo;penis&rdquo;
                in the top 5 of possible categories. Besides that we do not store any user data. We
                have used Amazon Mechanical turk for data collection to train the initial model.
              </p>

              <p>
                Frontend
                <br />
                <a href="https://reactjs.org/">React</a>, <a href="https://redux.js.org/">Redux</a>,{' '}
                <a href="https://www.tensorflow.org/js">TensorFlow.js</a>
              </p>

              <p>
                Model
                <br />
                <a href="https://github.com/tensorflow/models/tree/master/research/slim/nets/mobilenet">
                  Retrained MobileNetV2
                </a>
              </p>

              <p>
                Datasets
                <br />
                <a href="https://github.com/studiomoniker/Quickdraw-appendix">
                  Collected drawings from our users
                </a>
                <br />
                <a href="https://github.com/googlecreativelab/quickdraw-dataset">
                  The Quick, Draw! Dataset
                </a>
                <br />
              </p>
              <p>
                References
                <br />
                Our voiceover says a few amended lines taken from the film Ex Machina by Alex
                Garland &ldquo;Our conversations are one-sided. You draw something and study my
                responses.&rdquo; and &ldquo;This isn&apos;t a website, it&apos;s a training
                facility.&rdquo; For the swearing at the end we have used Orteil&apos;s Insult
                Generator.
              </p>
            </section>
            <section className="footer">
              <div className="credits">
                <p>
                  Concept & development by <a href="https://studiomoniker.com/">Moniker</a>:<br />
                  Luna Maurer &amp; Roel Wouters
                  <br />
                  Jolana Sykorova
                  <br />
                  Thomas Boland
                  <br />
                  Tjerk Woudsma
                  <br />
                  Jae Perris
                  <br />
                </p>

                <p>
                  Texts in collaboration with:
                  <br />
                  <a href="http://kathrinhero.nl/">Kathrin Hero</a>
                </p>

                <p>
                  Commissioned by:
                  <br />
                  <a href="https://www.mozilla.org">Mozilla</a>, Brett Gaylor
                  <br />
                  <a href="https://www.hkw.de/en">Haus der Kulturen der Welt, Berlin (HKW)</a>,
                  Daniel Neugebauer
                </p>
              </div>
              <div className="logos">
                <a href="https://www.mozilla.org">
                  <Mozilla />
                </a>
                <a href="https://www.hkw.de/en">
                  <HKW />
                </a>
              </div>
            </section>
          </div>
        </div>
        <Nav />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  paused: state.app.paused,
  muted: state.app.muted,
  debugShowing: state.app.showDebug,
})

const mapDispatchToProps = {
  pauseApp,
  resetApp,
  toggleMute,
  showDebug,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Colophon)
