import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import '../scss/ErrorScreen.scss'

class ErrorScreen extends Component {
  render() {
    return (
      <div className="ErrorScreen">
        <div className="message">
          <p>Something went wrong.</p>
          <p>Please try another browser or device to check out this project.</p>
        </div>
        <div className="learn-more">
          Learn more about this project <Link to="/colophon">↘ here</Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  title: state.app.title,
})

export default connect(mapStateToProps)(ErrorScreen)
