/* eslint-disable object-curly-newlinem, max-len */
import * as tf from '@tensorflow/tfjs'
import labelsMulti from '../resources/labelsMulti.json'

const ASSETS_URL = `${window.location.origin}/assets`
const MODEL_MULTI_URL = `${ASSETS_URL}/model-multi/model.json`
const MODEL_MULTI_IMAGE_SIZE = 224

class ModelProxy {
  static instance
  model
  labels

  constructor() {
    if (ModelProxy.instance) return ModelProxy.instance
    ModelProxy.instance = this
  }

  init = async () => {
    tf.ENV.set('WEBGL_PACK', false)
    this.modelMulti = await tf.loadLayersModel(MODEL_MULTI_URL)
    const inputMulti = tf.zeros([1, MODEL_MULTI_IMAGE_SIZE, MODEL_MULTI_IMAGE_SIZE, 1])

    this.modelMulti.predict(inputMulti)

    console.log('(ModelProxy: init)')
  }

  predictMulti = async (image, numResults = 20) => {
    if (!this.modelMulti) return new Error('Model not initialized')

    // Convert image to tensor, resize to network input size
    const t0 = performance.now()
    const img = tf.browser.fromPixels(image).toFloat()
    const resized = tf.image.resizeBilinear(img, [MODEL_MULTI_IMAGE_SIZE, MODEL_MULTI_IMAGE_SIZE])
    const grayscale = resized.mean(2).expandDims(2)
    const input = grayscale.expandDims(0)

    // Get predictions
    const output = await tf.tidy(() => this.modelMulti.predict(input)).data()
    const predictions = labelsMulti
      .map((label, index) => ({ label, accuracy: output[index] * 100 }))
      .sort((a, b) => b.accuracy - a.accuracy)
      .reduce((acc, curr) => (acc.length < numResults ? acc.concat(curr) : acc), [])
    const time = performance.now() - t0
    return { predictions, time }
  }

  predictLSTM = async (input) => {
    if (!this.modelLSTM) return new Error('Model not initialized')
    console.log(input)
  }

  destroy = () => {
    console.log('(ModelProxy: destroy)')
  }
}

export default new ModelProxy()
